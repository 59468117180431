
import '@/components/ui/RichText/css/richtext.scss';
import { Content, EditorContent } from '@tiptap/vue-2';
import {
  defineComponent, PropType, reactive, toRefs, watch,
} from 'vue';
import { RichEditor } from '@/components/ui/RichText/editor/RichEditor';
import parseRichContent from '@/components/ui/RichText/helpers/parseJsonContent';

export default defineComponent({
  name: 'RichTextViewer',
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: [String, Object] as PropType<Content>,
      default: '',
    },
  },

  setup(props) {
    const { value: content } = toRefs(props);

    const editor = reactive(new RichEditor({
      content: parseRichContent(content.value),
      editable: false,
    }));

    watch(content, (newVal) => {
      editor.setContent(parseRichContent(newVal));
    }, { immediate: true });

    return {
      editor,
    };
  },

  beforeUnmount() {
    this.editor.destroy();
  },
});
