import { Image as ImageExt } from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/vue-2';

// eslint-disable-next-line import/prefer-default-export
export const Image = ImageExt.extend({
  defaultOptions: {
    ...ImageExt.options,
    sizes: ['inline', 'block', 'left', 'right'],
  },
  renderHTML({ HTMLAttributes }) {
    const { style } = HTMLAttributes;
    return [
      'figure',
      { style },
      ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
    ];
  },
});
