import { Content } from '@tiptap/vue-2';
import { isString } from 'lodash';
import { IRichTextContent } from '@/components/ui/RichText/types';

export default function parseJsonContent(content?: Content) {
  if (!content) {
    return null;
  }
  if (isString(content)) {
    try {
      return JSON.parse(content) as IRichTextContent;
    } catch (e: any) {
      console.error(e);
      return '';
    }
  }
  return content;
}
