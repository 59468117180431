import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import { Color } from '@tiptap/extension-color';
import { Content, Editor, EditorOptions } from '@tiptap/vue-2';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import { Image } from '@/components/ui/RichText/extension';
import { IRichTextOutput } from '@/components/ui/RichText/types';

export function getExtensions() {
  return [
    StarterKit.configure({
      dropcursor: false,
      gapcursor: false,
    }),
    TextStyle,
    Link,
    Image,
    TextAlign.configure({
      types: ['heading', 'paragraph', 'image'],
    }),
    Color.configure({
      types: ['textStyle'],
    }),
    BubbleMenu,
  ];
}

export class RichEditor extends Editor {
  isFullscreen = false;

  constructor(options: Partial<EditorOptions> = {}) {
    super({
      ...options,
      extensions: getExtensions(),
    });
  }

  setContent(content: Content) {
    // HTML
    // const isSame = editor.getHTML() === newVal;

    // JSON
    const isSame = JSON.stringify(this.getJSON()) === JSON.stringify(content);

    if (isSame) {
      return false;
    }
    return this.commands.setContent(content, true);
  }

  setColor(val: string | null) {
    if (val) {
      this.chain()
        .focus()
        .setColor(val)
        .run();
    } else {
      this.chain()
        .focus()
        .unsetColor()
        .run();
    }
  }

  addImage() {
    // Это пример использования, переделать на модалку с формой
    // eslint-disable-next-line no-alert
    const url = window.prompt('URL',
      'https://pbs.twimg.com/profile_images/1455185376876826625/s1AjSxph_400x400.jpg');

    if (url) {
      this.chain()
        .focus()
        .setImage({ src: url })
        .run();
    }
  }

  setLink() {
    // Это пример использования, переделать на модалку с формой
    // eslint-disable-next-line no-alert
    const url = window.prompt('URL', 'https://www.google.com/');

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      this
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();

      return;
    }

    // update link
    this
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
  }

  // eslint-disable-next-line class-methods-use-this
  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
  }

  getOutput(): IRichTextOutput {
    return {
      json: this.getJSON(),
      html: this.getHTML(),
    };
  }
}
